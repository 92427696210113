<template>
  <v-container>
    <v-card outlined class="mx-auto" style="max-width: 30rem">
      <v-card-title class="justify-center"
        >Blinn College Directory</v-card-title
      >
      <v-form @submit.prevent="getFromDB()" ref="nameform" v-model="formvalid">
        <v-text-field
          class="mx-5"
          v-model="drsearchfirstname"
          label="First Name"
          outlined
          autofocus
        >
        </v-text-field>
        <v-text-field
          class="mx-5"
          v-model="drsearchlastname"
          label="Last Name"
          outlined
        >
        </v-text-field>
        <v-card-actions>
          <v-btn outlined class="ml-3" type="submit">Submit</v-btn>
          <v-btn
            outlined
            color="blue darken-2"
            class="mx-5"
            @click="resetForm()"
            >Reset</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
    <v-card outlined class="mx-auto" style="max-width: 30rem">
      <v-card-subtitle
        >Use the search toolbar above to search for specific Blinn College
        personnel, or find contact information on the program or departmental
        web page by using
        <a href="http://www.blinn.edu/blinn-a-z/index.html" target="_blank"
          >Blinn A to Z.</a
        ></v-card-subtitle
      >
    </v-card>
    <v-card outlined>
      <v-data-table
        dense
        :headers="this.headers"
        :items="this.entries"
        :items-per-page="10"
        class="elevation-1"
      ></v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "ListResults",
  methods: {
    async getResultsFromDB(beurl) {
      try {
        let results = await axios.get(beurl);
        let columns = await axios.get(`${this.backendurl}/getcolumns`);
        this.entries = [...results.data];
        let h = [];
        //          h.push({text:columns.data[0]["Field"],sortable: false, align: "start", value:columns.data[0]["Field"]})
        for (let i = 1; i < columns.data.length; i++) {
          let header = columns.data[i]["Field"];
          let val = columns.data[i]["Field"];
          if (header == "firstname") {
            header = "First Name";
          } else if (header == "lastname") {
            header = "Last Name";
          } else {
            header = header.charAt(0).toUpperCase() + header.slice(1);
          }
          h.push({ text: header, sortable: true, value: val });
        }
        this.headers = [...h];
        //          this.headers = [
        //            { text: "Name", align: "start", value: "name" },
        //            { text: "Address", value: "address" },
        //            { text: "Email", value: "email" },
        //            { text: "Phone", value: "phone" },
        //          ]
      } catch (err) {
        console.log(err);
        this.error = err;
      }
    },
    resetForm() {
      this.$refs.nameform.reset();
    },
    async getFromDB() {
      let beurl;
      if (this.drsearchfirstname && !this.drsearchlastname) {
        beurl = encodeURI(
          `${this.backendurl}/byfirstname/${this.drsearchfirstname}`
        );
      } else if (this.drsearchlastname && !this.drsearchfirstname) {
        beurl = encodeURI(
          `${this.backendurl}/bylastname/${this.drsearchlastname}`
        );
      } else if (this.drsearchlastname && this.drsearchfirstname) {
        beurl = encodeURI(
          `${this.backendurl}/byfullname/firstname/${this.drsearchfirstname}/lastname/${this.drsearchlastname}`
        );
      } else {
        this.$refs.nameform.reset();
        return;
      }
      await this.getResultsFromDB(beurl);
    },
  },
  data() {
    return {
      /*
      backendurl: "https://brblackbeard19.blinn.edu:4001/api"
      */
      backendurl: "https://directory.blinn.edu:4001/api",
      headers: [],
      entries: [],
      formvalid: true,
      drsearchfirstname: null,
      drsearchlastname: null,
    };
  },
};
</script>
